.app {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url("./assets/image/bg.JPG");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.app::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.container-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.content-box {
  min-width: 960px;
  min-height: 145px;
  padding-left: 120px;
}

.Typewriter {
  max-width: 815px;
  font-size: 18px;
  line-height: 45px;
  color: white;
  z-index: 1;
  opacity: 0.9;
}

.title {
  max-width: 801px;
  font-family: "digital";
  letter-spacing: 3px;
  font-size: 60px;
  font-weight: 600;
  line-height: 55px;
  color: white;
  opacity: 0.9;
  z-index: 1;
}

.discription {
  position: absolute;
  padding-bottom: 20px;
  bottom: 0;
  max-width: 900px;
  font-size: 14px;
  font-weight: 400;
  color: white;
  z-index: 1;
  opacity: 0.9;
}

@media (max-width: 1100px) {
  .content-box {
    min-width: 585px;
    padding-left: 58px;
  }

  .Typewriter {
    max-width: 508px;
    font-size: 11px;
    line-height: 19px;
  }

  .title {
    max-width: 508px;
    font-size: 30px;
    line-height: 37px;
  }

  .discription {
    font-size: 9px;
  }
}

@media (max-width: 600px) {
  .content-box {
    min-width: 369px;
    padding-left: 34px;
  }

  .Typewriter {
    max-width: 314px;
    font-size: 9px;
    line-height: 15px;
  }

  .title {
    max-width: 314px;
    font-size: 25px;
  }

  .discription {
    font-size: 10px;
  }
}

@media (max-width: 500px) {
  .content-box {
    min-width: 270px;
    padding-left: 25px;
  }

  .Typewriter {
    max-width: 270px;
    font-size: 8px;
    line-height: 13px;
  }

  .title {
    max-width: 270px;
    font-size: 20px;
    line-height: 29px;
  }

  .discription {
    font-size: 7px;
  }
}
